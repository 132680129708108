export const URI = process.env.VUE_APP_URI

export const SERVICE_NAMES ={
    USER: 'serv-user',
    LOGIN: 'serv-auth/login',
    SECURE_LOGIN: 'serv-auth/secure-login',
    LOGOUT: 'serv-auth/logout',
    PERMISSION: 'serv-auth/permissions',
    POLICY: 'serv-policy',
    ACTIVITY: 'serv-activity',
    GENERAL_DB: 'serv-general-db',
    PROCESS: 'serv-process',
    COLLABORATOR: 'serv-collaborator',
    MUNIMENT: 'serv-muniment',
    WEIGHING: 'serv-weighing',
    EVIDENCE: 'serv-evidence',
	EXOGENOUS: 'serv-exogenous',
    INSTRUMENT: 'serv-instrument',
    TARGET: 'serv-target',
    EMAIL : 'serv-email',
    REVIEW: 'serv-review',
    SMTP: 'serv-smtp',
    MATRIX: 'serv-matrix',
    IMPROVEMENT_PLAN: 'serv-improvement-plan',
    CONTRACT: 'serv-contract',
    IMAGES: 'serv-images',
    DASHBOARD: 'serv-dashboard',
    REPORT: 'serv-report',
    TEMPLATE: 'serv-template',
    NOTES: 'serv-notes'
}

export const HTTP_STATUS = {
    OK: 200,
    NO_FOUND: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    CONFLICT: 409,
    UNPROCESSABLE_ENTITY: 422,
    INTERNAL_SERVER_ERROR: 500,
}

//* MODULES 

export const MODULES ={
    HOME: 'Home',
    PROJECTS: 'Proyectos',
    CALENDAR: 'Mi Calendario',
    MATRIX: 'Matriz de evidencias',
    REVIEW: 'Evaluación final',
    TEACHER_REVIEW: 'Evaluación final (Docentes)',
    EVIDENCE: 'Evidencias',
    WEIGHING: 'Ponderacion', // @a MODULO PARA ACCESO A REALIZACIÓN DE PONDERACIÓN
    INSTRUMENTS: 'Instrumentos',
    INSTRUMENT: 'Instrumento', // @a MODULO PARA ACCESO A APLICACIÓN DE INSTRUMENTO
    SERVER: 'Servidor', // @q MODULO DE PAGINAS DE PRUEBA
    SMTP: 'SMTP',
    IMPROVEMENT_PLAN: 'Plan de Mejora',
    CONTRACT: 'Contrato',
    DASHBOARD: 'Dashboard',
    TEMPLATES: 'Integración',
    NOTES: 'Notas',
    MY_NOTES: 'Mis Notas'
}

export const SESSION_EXPIRATION = {
    IDLE_TIME: 15000, //15 seg -> 15 * 1000
    TIMEOUT: 1800000 //30 minutes -> 30 * 60 * 1000
}