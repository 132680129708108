// * Constantes para el calculado de fechas sugeridas de los Procesos -> Ciclos -> Etapas -> Actividades
export const MONTH_IN_MS = 2592000000; // * 30*24*60*60*1000
export const ONE_WEEK_IN_MS = 604800000; // * 7*24*60*60*1000
export const FACTOR_SHORT_PROCESS = 0.4; // * Valor porcentual de la duración total
export const FACTOR_LONG_PROCESS = 6; // * Valor en meses  
export const SHORT_PROCESS_THRESHOLD = 1.5; // * Valor en años  
export const LONG_PROCESS_THRESHOLD = 3; // * Valor en años 

//*  DATES 
export const DATES_TYPE = {
    START: 1,
    END: 2,
    SETTLEMENT: 3,
    OTHER: 4
}

//* MATRIX
export const MEN_MATRIX_LABELS = [
    "MATRIZ",
    "Condición",
    "Criterio",
    "Evidencia"
]
export const CNA_MATRIX_LABELS = [
    "MATRIZ",
    "Factor",
    "Característica",
    "Aspecto"
]

//* MATRIX
export const MEN_MATRIX_LABELS_SELECTORS = [
    { text: "Condición", value: 1 },
    { text: "Criterio", value: 2 },
]
export const CNA_MATRIX_LABELS_SELECTORS = [
    { text: "Factor", value: 1 },
    { text: "Característica", value: 2 },
]

//* PLAN
export const TYPE_ORIGIN = {
    INTERNAL: 1,
    EXTERNAL: 2,
    FILES: 3,
}

export const WHY = {
    ORIGIN_PERCEPTION : 1,
    ORIGIN_EVIDENCE : 2,
    ORIGIN_PLAN : 3,
    ORIGIN_EXOGEN : 4,
    ORIGIN_FIGURES : 5,
    ORIGIN_SIC : 6,
    ORIGIN_OTHER : 7,
    ORIGIN_INSTITUTION : 8,
    ORIGIN_SUPPORT : 9,
    ORIGIN_INDICATOR: 15,
    ORIGIN_EP_NOTE: 16,
    ORIGIN_ELEMENT_NOTE: 17,
    ORIGIN_PL_OBJECTIVE_STR : 18,
    ORIGIN_PL_ASPECT : 19,
    ORIGIN_EXTERNAL_RESPONSIBLES : 20,
    ORIGIN_EXTERNAL_LVL_RESPONSIBLE : 21,
    ORIGIN_EXTERNAL_IMPACT : 22
}

export const AC_TYPE = {
    PLAN: 1,
    DO: 2,
    VERIFY: 3,
    ACT: 4
}

export const RESULT_TYPE ={
    KPI: 1,
    QUANTITY: 2,
    DOCUMENT: 3,
    OTHER: 4
}
//* PERMISSIONS

export const PERMISSIONS = {
    CONSULT: 1,
    CREATION: 2,
    EDITION: 3,
    DELETE: 4,
    COMMENT: 5
}

//*  SUPPORTS
export const SUPPORTS_TYPE = {
    MUNIMENT: 1,
    EXOGENOUS: 2,
    LINK: 3,
    EVIDENCE: 4,
    ANNEX: 5,
    INSTRUMENTS: 6,
    EVIDENCE_LINK: 7,
    ANNEX_LINK: 8,
}

//* COLLABORATOR TYPES
export const COLLABORATOR_TYPE = {
    COL_COLLABORATOR: 'Colaborador',
    COL_FOLLOWER: 'Seguidor',
    COL_IN_CHARGE: 'Responsable principal',
    COL_IN_CHARGE_SECONDARY: 'Responsable secundario',
    COL_ADMIN_TECH: 'Administrador tecnico',
    COL_INTERNAL_EVALUATOR: 'Evaluador interno',
    COL_PEER_EVALUATOR: 'Evaluador par',
    COL_EXTERNAL_EVALUATOR: 'Evaluador externo',
    COL_PM_ADMIN: 'Administrador PM',
    COL_PM_FOLLOW: 'Seguidor PM',
    COL_DOC_EVALUATOR: 'Evaluador Docente',
    COL_INTEGRATOR: 'Integrador'
}

export const COLLABORATOR_TYPE_CODES = {
    COL_COLLABORATOR: 'COLABORADOR',
    COL_FOLLOWER: 'SEGUIDOR',
    COL_IN_CHARGE: 'SP_ADMIN',
    COL_IN_CHARGE_SECONDARY: 'DIR_CALIDAD',
    COL_ADMIN_TECH: 'ADMIN_TECH',
    COL_INTERNAL_EVALUATOR: 'AUD_INTERNO',
    COL_PEER_EVALUATOR: 'AUD_PAR',
    COL_EXTERNAL_EVALUATOR: 'AUD_EXTERNO',
    COL_PM_ADMIN: 'PM_ADMIN',
    COL_PM_FOLLOW: 'PM_FOLLOW',
    COL_EVALUAROR: 'DOC_EVALUATOR',
    COL_INTEGRATOR: 'INTEGRATOR'
}
export const EXPERT_EVALUATOR_ROLS = [
    "AUD_INTERNO",
    "AUD_PAR",
    "AUD_EXTERNO"
]

export const PM_ROLS = [
    'PM_ADMIN',
    'PM_FOLLOW'
]

export const TEACHER_EVALUATION_ROLS = [
    'DOC_EVALUATOR'
]

//* QUESTION
export const QUESTION_PRESENTATION = {
    BY_SECTION: 1,
    BY_QUESTION: 2,
    BY_DEVICE: 3
}
export const QUESTION_TYPES = {
    QUESTION_NUMERIC_RANGE: 1,
    QUESTION_BOOLEAN: 2,
    QUESTION_APRECIATION: 3,
    QUESTION_SHORT_TEXT: 4,
    QUESTION_LONG_TEXT: 5,
    QUESTION_DATE: 6,
    QUESTION_DATETIME: 7,
    QUESTION_UNIQUE_SELECTION: 8,
    QUESTION_MULTIPLE_SELECTION: 9,
    QUESTION_MATRIX: 10,
    QUESTION_QUALITATIVE_RANGE: 11,
    QUESTION_ORDER: 12,
    QUESTION_PARTNER: 13,
    QUESTION_MULTIMEDIA: 14,
    QUESTION_UNIQUE_RADIO: 15,
    QUESTION_DATA_POLICY: 16,
    QUESTION_NUMERIC_RANGE_DECIMAL: 17,
    QUESTION_MULTIPLE_SELECTION_MATRIX: 18,
    QUESTION_INSTRUMENT_PRESENTATION: 19,
    QUESTION_REFERENCE_MATRIX: 20,
    QUESTION_REFERENCE_PERCEPTION: 21,
    QUESTION_CONDITIONAL: 22,
    QUESTION_DEPENDENT_QUESTION: 23
}

export const QUESTION_RANGE = {
    QUESTION_RANGE_SMALL: 1,
    QUESTION_RANGE_MIDDLE: 2
}

export const AUDIOVISUAL_TYPES = {
    AUDIO: 'A',
    IMAGE: 'I',
    VIDEO: 'V',
}

export const PROCESS_TYPE = {
    QUALIFIED_REGISTRY: 'RC1330',
    EXPERT_STANDARD : 'EVA-PAR',
    TEACHER_EVALUATION: 'ED-PRO'
}

export const LEVEL_TYPE = {
    PRINCIPAL: {
        ID: 1,
        LABEL: 'Principal',
    },
    CAMPUS: {
        ID: 2,
        LABEL: 'Seccional',
    },
    FACULTY: {
        ID: 3,
        LABEL: 'Facultad',
    },
    PROGRAM: {
        ID: 4,
        LABEL: 'Programa',
    },
}

//* ITERATION

export const ITERATION = {
    STATUS: {
        OPTION_A: 1,
        OPTION_B: 2,
        OPTION_C: 3,
        OPTION_D: 4,
        OPTION_E: 5,
    },
    SUPPORTS_TYPE: {
        OPTION_A: 1,
        OPTION_B: 2,
        OPTION_C: 3,
        OPTION_D: 4,
        OPTION_E: 5,
        OPTION_F: 6,
        OPTION_G: 7,
        OPTION_H: 8,
    },
    DECIMAL: {
        OPTION_A: 1,
        OPTION_B: 2,
        OPTION_C: 3,
    },
}

//* FORMATOS
export const FORMATS = {
    ALL: 0,
    PDF: 1,
    EXCEL: 2,
    WORD: 3
}

//* FOLLOW ACTIVIDADES PM
export const INTERNAL_FOLLOW = 1
export const EXTERNAL_FOLLOW = 2

// * NOTES OBSERVATION TYPE
export const OBSERVATION_TYPE = {
    APPRECIATION: 1,
    NONCONFORMITY: 2,
    OBSERVATION: 3,
    CLOSE: 4,
    CONCLUSION: 5
}

export const ORIGIN_NOTE = {
    WEIGHING: 1,
    VALORACION: 2,
    IMPROVEMENT_PLAN: 3,
    PERCEPTION: 4,
    PROCESS: 7
}

export const HIGH_LEVEL = 1
export const LOW_LEVEL = 2