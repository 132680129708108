import Vue from 'vue'
import IdleVue from 'idle-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { SESSION_EXPIRATION } from './app_constants'
import { PERMISSIONS, PM_ROLS , COLLABORATOR_TYPE_CODES } from './domain_constants'
import { ValidationProvider, ValidationObserver, extend, localize, localeChanged, validate } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import es from 'vee-validate/dist/locale/es.json';
import en from 'vee-validate/dist/locale/en.json';
import VueI18n from 'vue-i18n'
import messages from './i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './stylessheets/style.scss'

import moment from 'moment';
import VueMoment from 'vue-moment';
import VueApexCharts from 'vue-apexcharts'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';

import { Icon } from '@iconify/vue2';

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import LiquorTree from 'liquor-tree'

import Editor from '@tinymce/tinymce-vue'

import Draggable from 'vuedraggable';

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('treeselect', Treeselect)
let timeoutId
Vue.use(IdleVue, { eventEmitter: new Vue(), idleTime: parseInt(SESSION_EXPIRATION.IDLE_TIME), store: store }) //15 seg

//* TOAST OPTIONS
const options = {
  position: "bottom-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: "button",
  icon: true,
  rtl: false
};

Vue.use(Toast, options);

Vue.component('icon', Icon)

Vue.component('multiselect', Multiselect)

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

// Load Locales ('en' comes loaded by default)
require('moment/locale/es');

// Choose Locale
moment.locale('es');

Vue.use(VueMoment, { moment });



Vue.component('DatePicker', DatePicker)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(LiquorTree)

Vue.config.productionTip = false

Vue.use(VueI18n)

Vue.component('Editor', Editor)

Vue.component('Draggable', Draggable)

export const i18n = new VueI18n({
  locale: 'es', // set locale
  messages, // set locale messages
  silentTranslationWarn: true
})


localize({
  es,
  en
});
localize('es');
localeChanged();

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule]// copies rule configuration
  });
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// * Mixins

Vue.mixin({
  data() {
    return {
      asterisk: '<span style="color: red">*</span>',

      treeselectI18N: {
        searchPromptText: 'Escribe para buscar...',
        retryTitle: 'Cliquea para reintentar.',
        retryText: '¿Reintenta?',
        noResultsText: 'Sin resultados...',
        noOptionsText: 'Sin opciones disponibles.',
        noChildrenText: 'Sin sub-oopciones.',
        loadingText: 'Cargando...',
        clearValueText: 'Limpiar valor',
        clearAllText: 'Limpiar todo'
      }
    }
  },
  mounted() {
    if (this.$route && this.$route.params && this.$route.params.ext_token == undefined) {
      this.$store.commit('setExt', null)
    }
  },
  watch: {
    pmRol(newValue, oldValue) {
      if (newValue) {
        if (this.myRols.length > 0) {
          if (this.myRols[0] == COLLABORATOR_TYPE_CODES.COL_PM_ADMIN) {
            router.push({
              name: "improvement-plan",
            });
          }
          if (this.myRols[0] == COLLABORATOR_TYPE_CODES.COL_PM_FOLLOW) {
            router.push({
              name: "activity-ip",
            });
          }
        }
      }
    }
  },
  computed: {
    menus() {
      return this.$store.getters.getMenu;
    },
    myPermissions() { // * Todos los permisos por rutas
      if (this.$store.getters.getPermissions) {
        return this.$store.getters.getPermissions.permissions;
      }
      return []
    },
    myRols() { // * Todos los permisos por rutas
      if (this.$store.getters.getPermissions) {
        return this.$store.getters.getPermissions.rols;
      }
      return []
    },
    pmRol() {
      if (this.$store && this.$store.getters && this.$store.getters.getPermissions) {
        return !!(this.$store.getters.getPermissions.rols.length == 1 && PM_ROLS.includes(this.$store.getters.getPermissions.rols[0]));
      }
      return false
    },
    currentPermission() { // * Permisos de la ruta actual por defecto consulta
      if (this.myPermissions[this.$route.name] == undefined) return [PERMISSIONS.CONSULT]
      else return this.myPermissions[this.$route.name]
    },
    isLoading() {
      return this.$store.getters.isLoading > 0;
    },

    // * PERMISSIONS VARS -> Permite validar que si tiene ese permiso en la ruta actual
    canConsult() {
      return (this.$route.meta.permissions != null && this.$route.meta.permissions.includes(PERMISSIONS.CONSULT) && this.currentPermission.includes(PERMISSIONS.CONSULT))
    },
    canCreate() {
      return (this.$route.meta.permissions != null && this.$route.meta.permissions.includes(PERMISSIONS.CREATION) && this.currentPermission.includes(PERMISSIONS.CREATION))
    },
    canEdit() {
      return (this.$route.meta.permissions != null && this.$route.meta.permissions.includes(PERMISSIONS.EDITION) && this.currentPermission.includes(PERMISSIONS.EDITION))
    },
    canDelete() {
      return (this.$route.meta.permissions != null && this.$route.meta.permissions.includes(PERMISSIONS.DELETE) && this.currentPermission.includes(PERMISSIONS.DELETE))
    },
    canComment() {
      return (this.$route.meta.permissions != null && this.$route.meta.permissions.includes(PERMISSIONS.COMMENT) && this.currentPermission.includes(PERMISSIONS.COMMENT))
    },

    cycles() {
      return this.$store.getters.getAllCycles
    },
    selectedCycle() {
      return this.$store.getters.getCurrentCycle

    },
    currentContract() {
      return this.$store.getters.getCurrentContract
    },
    currentProcess() {
      return this.$store.getters.getCurrentProcess
    },
  },
  methods: {
    precision(value, precision, type) {
      let v = parseFloat(value),
        p = Math.max(precision, 0) || 0,
        t = type || 'round';
      return (Math[t](v * Math.pow(10, p)) / Math.pow(10, p)).toFixed(p);
    },
    isLeapYear: function (year) {
      if (year % 400 === 0) return true
      return (year % 100 === 0) ? false : year % 4 === 0;
    },
    customValidate: function (value, rules, name) {
      return validate(value, rules, { name: name }).then();
    },
    isNumeric: function (str) {
      if (typeof str != "string") return false // we only process strings!  
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    // * PERMISSIONS INTERCEPTORS -> enviarle la función para determinar si puede ejecutarla
    _consult: async function (callback) {
      if (!this.canConsult)
        return
      await callback
    },
    _edit: async function (callback) {
      if (!this.canEdit)
        return
      await callback
    },
    _create: async function (callback) {
      if (!this.canCreate)
        return
      await callback
    },
    _delete: async function (callback) {
      if (!this.canDelete)
        return
      await callback
    },
    _comment: async function (callback) {
      if (!this.canComment)
        return
      await callback
    },
  }
})

new Vue({
  router,
  store,
  i18n,
  onIdle() {
    if (store.getters.isAuthenticated && store.getters.isLoading == 0) {
      timeoutId = setTimeout(() => { store.dispatch("logOut", 'idle') }, SESSION_EXPIRATION.TIMEOUT)
    }
  },
  onActive() {
    clearTimeout(timeoutId)
  },
  render: h => h(App)
}).$mount('#app')

